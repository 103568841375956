import React, {useState} from 'react'
import { livestreamHolder } from '../styles/pages.module.scss'
import Seo from '../components/utilities/Seo'
import { motion, AnimatePresence } from 'framer-motion'
import {Link } from "gatsby"

import CountdownTimer from '../components/components/CountdownTimer'


export default function LivestreamPage() {
  const [countdownComplete, setCountdownComplete] = useState(false)

  const variants = {
    pre: {
      opacity:0,
    },
    visible:{
      opacity:1,
      transition: {
        type: 'tween',
        duration:2
      }
    },
    post: {
      opacity:0,
      transition: {
        type: 'tween',
        duration:2
      }
    }
  }

  return (
    <article className={livestreamHolder}>
      <Seo 
        title="The Woman You Want Livestream Event"
        description="On Wednesday 11th August Eliza Shaddad is coming to your living rooms live from Cornwall to premiere a series of videos that were recorded in May at Battersea Arts Centre with a 10 piece band. The event will also feature a number of live solo performances, behind the scenes mini-documentary, headphone giveaway and a live Q&A."
      />
      <AnimatePresence exitBeforeEnter>
        
      {/* { !countdownComplete && <motion.div variants={variants} initial="pre" animate="visible" exit="post" key="timer">
        <CountdownTimer setCountdownComplete={setCountdownComplete} />
      </motion.div> } */}

      {/* {
      !countdownComplete && 
      <motion.div variants={variants} initial="pre" animate="visible" exit="post" key="info">
          <div className="livestreamInfo">
          <p>On Wednesday 11th August Eliza Shaddad is coming to your living rooms live from Cornwall to premiere a series of videos that were recorded in May at Battersea Arts Centre with a 10 piece band.</p>
          <p>The event will also feature a number of live solo performances, behind the scenes mini-documentary, headphone giveaway and a live Q&A.</p>
        </div>


        <h3>Watch the trailer:</h3>
        <div className="videoHolder" >
          <iframe
            src="https://www.youtube.com/embed/Uq9NNe5k6Y8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </div>
      </motion.div>
      } */}

      <motion.div variants={variants} initial="pre" animate="visible" exit="post" key="enter">
        <h3><Link to="/livestream-premiere">Enter</Link></h3>
      </motion.div> 

      </AnimatePresence>


      
    </article>
  )
}
